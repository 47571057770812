<template>
  <div class="menu-content flex-box">
    <TableDetail :info="record" @back="handleBack" v-show="record" class="flex-grow"></TableDetail>
    <div class="flex-grow content md no-padding flex-box" v-show="!record">
      <div class="book-box flex-box vertical">
        <div class="book-search">
          <a-input-search placeholder="请输入内容搜索" enter-button="搜索" @search="onSearch" allowClear />
        </div>
        <div class="book-ctx flex-grow">
          <div
            class="system-item flex-box align-center"
            :class="{ active: system.value == selectedKey }"
            v-for="system in showList"
            :key="system.value"
            @click="selectSystem(system.value)"
          >
            <a-icon type="folder" class="folder" />
            <span class="flex-grow system-item-name">{{ system.label }}</span>
          </div>
        </div>
      </div>
      <div class="flex-grow table-content flex-box vertical">
        <data-list
          v-if="selectedKey"
          ref="dataList"
          class="flex-grow"
          :url="url"
          :query="query"
          hide-show-detail
          hide-edit
          hide-delete
          show-row-num
          :columns="columns"
          :search-key-type="searchKeyType"
          :requestConfig="{ noTempleFilter: true }"
        >
          <template v-slot:query="{ form }">
            <a-form-model-item>
              <a-input v-model="form.name" placeholder="输入表名搜索" allowClear></a-input>
            </a-form-model-item>
          </template>
          <template v-slot:action="{ item }">
            <div class="row-btn" @mousedown.stop @mouseup.stop>
              <a class="txt-btn" @click.stop="showDetail(item)">查看</a>
              <a class="txt-btn" @click.stop="downloadRow(item)">{{ item.exporting ? "下载中..." : "下载" }}</a>
            </div>
          </template>
        </data-list>
      </div>
    </div>
  </div>
</template>

<script>
import { clone, downLoadBlobFile } from "../../common/js/tool";
import { systemOptions } from "../../common/core/type";
import { hasTableAuthPromise } from "../../common/core/role";
import TableDetail from "./components/TableDetail.vue";

export default {
  name: "Menu",
  components: {
    TableDetail,
  },
  data() {
    return {
      selectedKey: null,
      roleList: [...systemOptions],
      showList: [...systemOptions],
      url: "/admin/data-table",
      searchKeyType: {
        name: 1,
      },
      columns: [
        { title: "表名", dataIndex: "name" },
        { title: "更新时间", dataIndex: "update_time" },
      ],
      record: null,
    };
  },
  computed: {
    query() {
      let query = "&sort=-id&expand=col";
      if (this.selectedKey) {
        query += `&filter[type]=${this.selectedKey}`;
      }
      return query;
    },
  },
  methods: {
    selectSystem(key) {
      if (this.selectedKey == key) {
        this.selectedKey = null;
        return;
      }
      this.selectedKey = key;
    },
    // 搜索
    onSearch(value) {
      this.showList = this.roleList.filter((item) => item.label.indexOf(value) > -1);
    },
    // 查看
    showDetail(item) {
      hasTableAuthPromise.call(this, item.id).then(() => {
        this.record = clone(item);
      });
    },
    handleBack() {
      this.record = null;
    },
    // 下载
    downloadRow(item) {
      hasTableAuthPromise.call(this, item.id).then(() => {
        if (item.exporting) {
          return;
        }
        this.$set(item, "exporting", true);
        this.$axios({
          url: "/admin/data-table-col/export",
          method: "POST",
          data: { table_name: item.real_name },
          noTempleFilter: true,
          responseType: "blob",
        })
          .then((res) => {
            downLoadBlobFile(res, "表数据");
          })
          .finally(() => {
            this.$set(item, "exporting", false);
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.menu-content {
  margin: 0 auto;
}

.book-box {
  width: 300px;
  border-right: var(--border);
}
.book-search {
  padding: 10px;
  border-bottom: var(--border);
}
.book-ctx {
  flex: 1;
  position: relative;
  min-height: 0;
  overflow: auto;
  padding: 8px 0;
}
.folder {
  color: @primary-color;
}
.system-item {
  height: 40px;
  padding: 0 16px;
  font-size: 16px;
  margin-bottom: 4px;
  cursor: pointer;
  &-name {
    margin-left: 12px;
  }
  &.active {
    background-color: #bae7ff;
  }
  &:hover {
    background-color: #bae7ff;
  }
}

.table-content {
  min-height: 0;
  overflow-y: auto;
  padding: 16px;
}
</style>

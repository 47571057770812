<template>
  <div class="content md no-padding flex-box vertical">
    <div class="detail-content area vertical">
      <a-descriptions title="基本信息" bordered>
        <a-descriptions-item label="表名" :span="3">
          {{ detail.name || "-" }}
        </a-descriptions-item>
        <a-descriptions-item label="更新时间" :span="3">
          {{ detail.update_time || "-" }}
        </a-descriptions-item>
        <a-descriptions-item label="下载" :span="3">
          <a-button type="primary" :loading="exporting" @click="download">下载xls</a-button>
        </a-descriptions-item>
      </a-descriptions>
      <div class="detail-title">数据项说明</div>
      <a-table
        bordered
        :pagination="false"
        :columns="columns"
        :row-key="(record) => record.id"
        :data-source="list"
        :loading="loading"
      >
      </a-table>
    </div>
    <a-space class="footer">
      <a-button @click="back">返回</a-button>
    </a-space>
  </div>
</template>

<script>
import { hasTableAuthPromise } from "../../../common/core/role";
import { downLoadBlobFile, clone } from "../../../common/js/tool";

export default {
  name: "TableDetail",
  props: {
    info: Object,
  },
  data() {
    return {
      detail: {},
      list: [],
      loading: false,
      columns: [
        { title: "字段名称", dataIndex: "col_desc" },
        { title: "数据类型", dataIndex: "type" },
      ],
      exporting: false,
    };
  },
  watch: {
    info(val) {
      if (val) {
        this.getDetail();
      } else {
        this.detail = {};
        this.list = [];
      }
    },
  },
  methods: {
    getDetail() {
      this.detail = clone(this.info);
      this.list = this.detail?.col || [];
    },
    download() {
      let item = this.detail;
      hasTableAuthPromise.call(this, item.id).then(() => {
        if (this.exporting) {
          return;
        }
        this.exporting = true;
        this.$axios({
          url: "/admin/data-table-col/export",
          method: "POST",
          data: { table_name: item.real_name },
          noTempleFilter: true,
          responseType: "blob",
        })
          .then((res) => {
            downLoadBlobFile(res, "表数据");
          })
          .finally(() => {
            this.exporting = false;
          });
      });
    },
    back() {
      this.$emit("back");
    },
  },
};
</script>

<style lang="less" scoped>
.detail-content {
  padding: 16px 32px;
}
.detail-title {
  margin-top: 24px;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
}
.footer {
  width: 100%;
}
</style>

import { getStorageInfo, getUserWxId } from "../../common/js/storage";
/**
 * 判断是否有表权限
 * @param {Number|String} id 表单id
 */
export const hasTableAuthPromise = function(id) {
  let user_id = getUserWxId();
  let info = getStorageInfo();
  if (info?.role == 1) {
    // 系统超管
    return Promise.resolve();
  } else if (info?.userRole && info.userRole.length > 0) {
    let flag = info.userRole.some((user) => user.system == 11 && user.privilege == 1); // 核心数据平台超管
    if (flag) {
      return Promise.resolve();
    }
  }
  return this.$axios({
    url: "/admin/data-role",
    method: "GET",
    params: {
      "filter[table_ids][fis]": id,
      "filter[visible_ids][fis]": user_id,
    },
    noTempleFilter: true,
  }).then((res) => {
    let flag = res.data.length > 0;
    if (flag) {
      return Promise.resolve();
    } else {
      this.$message.warning("没有权限");
      return Promise.reject();
    }
  });
};
